import { completePayment } from "./initPayment.js";
import { notifyBot } from '../initMisc.js';

declare const paypal: any;
export default function initPaypal(form: HTMLFormElement, transactionDetails: any, errors: string[], container: HTMLElement) {
  try {

    let orderIdElement = document.getElementById("payment-orderId") as HTMLInputElement;
    let orderId = orderIdElement.value;

    let ipnIdElement = document.getElementById("payment-ipnId") as HTMLInputElement;
    let ipnId = ipnIdElement.value;

    //1. Generate Paypal card fields
    let cardField = createCardFields(form, errors, orderId, ipnId, transactionDetails);

    //2. Generate the yellow Paypal button
    createPaypalButton(transactionDetails, ipnId, container);

    //3. Setup submit button for card fields
    setupCardFieldSubmitButton(cardField, form, orderId, ipnId, errors);

  } catch (e) {
    notifyBot(`initPaypal script failed. Error: \z\z${e}`);
  }
}

function createCardFields(form: HTMLFormElement, errors: any, orderId: string, ipnId: string, transactionDetails: string): any {
  const cardStyle = {
    'input': {
      'padding': '0.5rem 1rem'
    }
  };

  let cardField = paypal.CardFields({
    style: cardStyle,
    inputEvents: {
      onChange: (data) => {
        if (!data.isFormValid) {
          form.classList.add("invalid");
          errors = data.errors;
        } else {
          form.classList.remove("invalid");
        }
      }
    },
    createOrder: () => {
      return fetch("/Account/Payment/CreateOrder", {
        method: 'post',
        body: transactionDetails
        // use the "body" param to optionally pass additional order information like
        // product ids or amount.
      })
        .then((res) => res.json())
        .then((orderData) => {
          orderId = orderData.result.Id; // needed later to complete capture
          return orderData.result.Id
        })
    },
    onApprove: function (data, actions) {
      const { liabilityShift, orderID } = data;
      if (liabilityShift) {

      }
      return fetch(`/Account/Payment/ProcessPayment?orderId=${orderID}&ipnId=${ipnId}`, {
        method: 'post'
      }).then(function (res) {
        return res.json();
      }).then(function (orderData) {
        if (orderData.error == "INSTRUMENT_DECLINED") {
          return actions.restart();
        }

        completePayment(orderData, ipnId);
      });
    },
    onError: function (err) {
      console.log(err.message);
      completePayment(err, false);
    }
  });

  if (cardField.isEligible()) {
    const nameField = cardField.NameField();
    nameField.render("#card-name-field-container");

    const numberField = cardField.NumberField();
    numberField.render("#card-number-field-container");

    const cvvField = cardField.CVVField();
    cvvField.render("#card-cvv-field-container");

    const expiryField = cardField.ExpiryField();
    expiryField.render("#card-expiry-field-container");

    let paypalCardButton = document.getElementById("paypal-card-button") as HTMLButtonElement;
    paypalCardButton.classList.remove("d-none");
  }

  return cardField;
}

function setupCardFieldSubmitButton(cardField: any, form: HTMLFormElement, orderId: any, ipnId: string, errors: string[]) {
  //card field elements
  const cardHolderName = document.getElementById("card-name-field-container") as HTMLInputElement;
  const postalCode = document.getElementById("card-billing-address-zip") as HTMLInputElement;
  const countryCode = document.getElementById("card-billing-address-country") as HTMLInputElement;

  document
    .getElementById("card-field-submit-button")
    .addEventListener("click", (e: MouseEvent) => {
      e.preventDefault();
      let target = e.target as HTMLButtonElement;
      if (form.classList.contains("invalid")) {
        let errorList = errors.join("\n").replace("_", " ").toLowerCase();
        confirm(`Form is invalid: \n${errorList}`);
        return;
      }

      target.disabled = true;
      target.innerHTML = "Please wait... <i class='fa fa-sync fa-spin'></i>";

      cardField
        .submit({
          cardholderName: cardHolderName.value,
          // Billing Address
          billingAddress: {
            // Postal Code
            postalCode: postalCode.value,
            // Country Code
            countryCode: countryCode.value,
          },
        }).then(() => {
          // submit successful
        })
        .catch((err) => {
          console.log(err.message);
          completePayment(err, false);
        });
    });
}

function createPaypalButton(transactionDetails: any, ipnId: string, container: HTMLElement) {

  //Create the paypal button container
  let div = document.createElement("div");
  div.id = "paypal-button-container";
  container.appendChild(div);

  //Create the paypal button
  paypal.Buttons({
    // Call your server to set up the transaction
    createOrder: function (data, actions) {
      return fetch('/Account/Payment/CreateOrder', {
        method: 'post',
        body: transactionDetails
      }).then(function (res) {
        return res.json();
      }).then(function (orderData) {
        //console.log("ORDER ID:" + orderData.result.Id);
        return orderData.result.Id;
      });
    },

    // Call your server to finalize the transaction
    onApprove: function (data, actions) {
      return fetch(`/Account/Payment/ProcessPayment?orderId=${data.orderID}&ipnId=${ipnId}`, {
        method: 'post'
      }).then(function (res) {
        return res.json();
      }).then(function (orderData) {
        if (orderData.error == "INSTRUMENT_DECLINED") {
          return actions.restart();
        }

        completePayment(orderData, ipnId);
      });
    },
    onError: function (err) {
      console.log(err.message);
      completePayment(err, false);
    }

  }).render('#paypal-button-container');
}