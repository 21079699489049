import { savedSearches, SavedSearchCookie } from "./cookies.js";
import showModal from "./showModal.js";

export default function initSavedSearches() {
  let savedSearchesContainer = document.getElementById("SavedSearches") as HTMLElement;

  if (savedSearchesContainer && savedSearches && savedSearches.length > 0) {
    savedSearches.forEach(element => {

      savedSearchesContainer.appendChild(createSavedSearchElement(element));
    });
  }
}

function createSavedSearchElement(savedSearch: SavedSearchCookie) {
  let li = document.createElement("li") as HTMLLIElement;
  li.id = `SavedSearch_${savedSearch.SavedSearchId}`;
  li.classList.add("list-group-item");

  let link = document.createElement("a") as HTMLAnchorElement;
  link.href = savedSearch.Url;
  link.target = "_blank";
  link.innerHTML = `<i class="far fa-play"></i> ${savedSearch.Name}`;

  let deleteButton = document.createElement("a") as HTMLAnchorElement;
  deleteButton.href = `/SavedSearches/Remove/${savedSearch.SavedSearchId}`;
  deleteButton.title = "Delete this search";
  deleteButton.classList.add("float-end");
  deleteButton.innerHTML = `<i class="far fa-trash-alt"></i>`;


  deleteButton.addEventListener("click", (e) => {
    e.preventDefault();
    fetch(deleteButton.href, { method: "POST", headers: { "X-Requested-With": "XMLHttpRequest" } })
      .then((response) => response.json())
      .then(data => {
        if (data.body) {
          if (data.type == 2 /*success*/) {
            li.remove();
          } else {
            showModal(data.title, data.body);
          }
        }
      });
  });

  li.appendChild(link);
  li.appendChild(deleteButton);
  return li;
}