export default function initNavBar() {
  let navbar = document.querySelector('.navbar') as HTMLElement;

  if (navbar == null) return;

  let navbarRight = document.getElementById('navbar-right') as HTMLElement;
  let navbarLeft = document.getElementById('navbar-left') as HTMLElement;
  let navbarCentre = document.getElementById('navbar-centre') as HTMLElement;
  let navbarTopSearchBox = document.getElementById('navbar-top-search-box') as HTMLElement;

  let dropdownMenu = navbarTopSearchBox.querySelector('.dropdown-menu') as HTMLElement;
  let input = navbarTopSearchBox.querySelector('input') as HTMLInputElement;
  let form = navbarTopSearchBox.querySelector('form') as HTMLFormElement;
  let button = navbarTopSearchBox.querySelector('a') as HTMLAnchorElement;
  let searchBox = navbarTopSearchBox.querySelector('.search-box') as HTMLAnchorElement;
  let searchBoxIcon = navbarTopSearchBox.querySelector('.search-box-icon') as HTMLAnchorElement;

  button.addEventListener('click', function () {
    input.classList.remove('d-none');
    //dropdownMenu.style.display = "initial";
    navbarCentre.classList.add("d-none");
    navbarRight.classList.add("col-10");
    navbarRight.classList.remove("col-5");
    navbarLeft.classList.remove("col-5");
    navbarLeft.classList.add("col-2");
    button.classList.add("d-none");
    searchBoxIcon.classList.remove("d-none");
    searchBox.classList.remove("d-none");
    input.focus();
  });

  input.addEventListener("blur", (e) => {
    setTimeout(() => {
      input.classList.add('d-none');
      navbarCentre.classList.remove("d-none");

      navbarRight.classList.remove("col-10");
      navbarRight.classList.add("col-5");
      navbarLeft.classList.add("col-5");
      navbarLeft.classList.remove("col-2");
      dropdownMenu.style.display = "none";
      button.classList.remove("d-none");
      searchBoxIcon.classList.add("d-none");
      searchBox.classList.add("d-none");
    }, 100); // Delay hiding to allow click event on link to be processed
  });

  input.addEventListener("input", (e) => {
    if (input.value.length >= 6) {
      dropdownMenu.style.display = "initial";
    } else {
      dropdownMenu.style.display = "none";
    }
  });

  input.addEventListener("focus", (e) => {
    if (input.value.length >= 6) {
      dropdownMenu.style.display = "initial";
    } else {
      dropdownMenu.style.display = "none";
    }
  });

  dropdownMenu.addEventListener("mousedown", (e) => {
    // Check if the click is on an anchor element within the dropdown menu
    let target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      e.preventDefault(); // Prevent the link from being followed immediately
      window.location.href = (target as HTMLAnchorElement).href; // Follow the link
    }
  });

  document.addEventListener("mousedown", (e) => {
    if (!dropdownMenu.contains(e.target as Node) && e.target !== input) {
      input.classList.add('d-none');
      navbarCentre.classList.remove("d-none");
      navbarRight.classList.remove("col-10");
      navbarRight.classList.add("col-5");
      navbarLeft.classList.add("col-5");
      navbarLeft.classList.remove("col-2");
      dropdownMenu.style.display = "none";
    }
  });
}
