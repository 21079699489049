import showModal from "../showModal.js";
import initGooglePay from "./initGooglePay.js";
import initApplePay from "./initApplePay.js";
import initPaypal from './initPaypal.js';
import { notifyBot } from '../initMisc.js';

export default function initPayment() {

  let form = document.getElementById("card-form") as HTMLFormElement;
  let errors = ["Fields are empty"];
  if (form == null) return;
  //Get the details about the transaction from the hidden input field
  let transactionDetailsElement = document.getElementById("payment-transaction-details") as HTMLInputElement;
  let paymentMethods = document.getElementById("payment-methods") as HTMLElement;
  let transactionDetails = transactionDetailsElement.value;

  //Initialise Paypal Payment
  initPaypal(form, transactionDetails, errors, paymentMethods);

  //Initialise Google Pay
  //initGooglePay(transactionDetails);

  //Initialise Apple Pay
  initApplePay(transactionDetails);

  initBankTransfer();

  //init retry button
  document.getElementById("retry-payment").addEventListener("click", function () {
    history.back();
  })
}
export function completePayment(orderData: any, ipn: any) {
  let payNowBtn = document.getElementById("card-field-submit-button") as HTMLButtonElement;

  try {

    //Payment was successful
    if (orderData.response != null && orderData.response == 200 && ipn != false) {
      payNowBtn.innerHTML = "Redirecting... <i class='fa fa-sync fa-spin'></i>";
      window.location.replace(window.origin + '/Account/Payment/PaymentCompleted?id=' + ipn);
    } else {
      throw new Error("Payment failed");
    }
  } catch (e) {
    //Payment was unsuccessful
    console.log(e.message);
    console.error(e.stack);

    let errorModalTitle;
    let errorModalDescription;
    let errorDetail;
    if (orderData) {
      errorDetail = Array.isArray(orderData.details) && orderData.details[0];

    }
    //Payer's card was declined
    if (orderData.response != null && orderData.response == 406) {
      errorModalTitle = "Payment Declined";
      errorModalDescription = "Please double check your card details, try a different card, or payment method.";
    } else if (errorDetail) {
      //Payment failed for reasons other than a declined card
      errorModalTitle = "Payment Failed";
      errorModalDescription = errorDetail.description;
    } else if (orderData.authenticationReason != null) {
      //3D Secure check failed
      errorModalTitle = "3D Secure check failed";
      errorModalDescription = "Please double check your dcard details, try again or use a different payment method.";
    } else if (orderData.message != null && orderData.message.includes("UNPROCESSABLE_ENTITY")) {
      alert("Card failed. Either details were entered incorrectly or the card does not exist.");
      location.reload();
    }

    if (errorModalTitle != null) {
      showModal(errorModalTitle, errorModalDescription ?? "", 3);
    }

    //Re-enable payment button so the user can try again
    payNowBtn.innerHTML = "Pay now";
    payNowBtn.disabled = false;
  }
}

function initBankTransfer() {
  let confirmedBankTransfer = document.getElementById("confirmedBankTransfer") as HTMLButtonElement;
  if (confirmedBankTransfer == null) return;

  let amountIncVat = document.getElementById("payment-total") as HTMLInputElement;
  let currency = document.getElementById("payment-currency") as HTMLInputElement;
  let userId = document.getElementById("payment-userid") as HTMLInputElement;
  let firstName = document.getElementById("payment-billing_first_name") as HTMLInputElement;
  let lastName = document.getElementById("payment-billing_last_name") as HTMLInputElement;
  let bankCharge = document.getElementById("payment-bank_charge") as HTMLInputElement;
  let description = document.getElementById("payment-description") as HTMLInputElement;


  //Bank Transfer Notification button
  confirmedBankTransfer.addEventListener("click", function () {

    confirmedBankTransfer.classList.add("disabled");
    confirmedBankTransfer.innerHTML = "Notifying... <i class='fa fa-sync fa-spin'></i>";

    try {


      fetch('/Account/Payment/ConfirmBankTransfer', {
        method: 'post',
        body: `{"userid": ${userId.value}, "name": "${firstName.value} ${lastName.value}", "description": "${description.value}", "total": "${amountIncVat.value} ${currency.value}", "bankcharge": "${bankCharge.value}"}`
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.result != 200) {
            throw "failed";
          }
          confirmedBankTransfer.innerHTML = "Notified!";
        })
        .catch((error) => {

          confirmedBankTransfer.classList.remove("disabled");
          confirmedBankTransfer.innerHTML = `Failed to notify with error ${error}. Retry?`;
        });
    } catch (error) {
      confirmedBankTransfer.classList.remove("disabled");
      confirmedBankTransfer.innerHTML = `Failed to notify with error ${error}. Retry?`;
    }
  })

}