
import { checkAcceptCookies, getCookie } from './cookies.js';
import initLocationSearchInputs from './initLocationSearchInputs.js';
import initUserAlerts from './initUserAlerts.js';
import initShareButtons from './initShareButtons.js';
import initHTMXExtras from './initHTMXExtras.js';
import initFavourites from './initFavourites.js';
import initMisc, { handleErrors, showAuthorisedElements, initRangeSliderValue, initDistances, initGLightboxes, initProformaInvoice, initBackButton, initCountryPrivacySettings } from './initMisc.js';
import initSubmitButtons from './initSubmitButtons.js';
import initHeaderSearchForm from './initHeaderSearchForm.js';
import initSearchForm from './initSearchForm.js';
import initSettings from './initSettings.js';
import uploadProfilePhoto from './uploadProfilePhoto.js'
import initCountryRegionInputs from './initCountryRegionInputs.js';
import initMaps from './initMaps.js';
import { showAlertModals } from './showModal.js';
import initFeedbackForm from './initFeedbackForm.js';
import initPreviousEmployers from './initPreviousEmployers.js';
import initClickImpressionCounts from './initClickImpressionCounts.js';
import { initFormInputGroups } from './formTools.js';
import initBusinessAds from './initBusinessAds.js';
import initPullFromServer from './initPullFromServer.js';
import initCharCount from './initCharCount.js';
import initNavBar from './initNavBar.js';
import initSavedSearches from './initSavedSearches.js';
import initPayment from './payment/initPayment.js';
declare const umami: any;
function initSite() {
  //handleErrors();

  //Initialise the location/postcode input fields.
  initLocationSearchInputs(null);

  //Initialise the share button script
  initShareButtons();
  initBackButton();
  ///Initialise the extra HTMX code
  initHTMXExtras();

  initHeaderSearchForm();
  initNavBar();

  initFavourites();
  initSearchForm();
  initMisc();
  initSubmitButtons();
  showAuthorisedElements();
  initRangeSliderValue()
  //initForms();
  initSettings();
  uploadProfilePhoto();
  initCountryRegionInputs();
  //initFAQs();
  //initMaps();
  showAlertModals();
  initUserAlerts();
  initSavedSearches();

  initClickImpressionCounts();

  //initAddAddress();
  initFormInputGroups();

  checkAcceptCookies();
  initFeedbackForm();

  initGLightboxes();

  initPreviousEmployers();
  //initPreferredLocations();

  initBusinessAds();
  initProformaInvoice();

  initCountryPrivacySettings();

  initPullFromServer();

  initCharCount();
  initPayment();

  (window as any).initMaps = initMaps;

}
document.addEventListener("DOMContentLoaded", function () {
  initSite();
});

function removeElementWithFade(element) {

  //
  element.remove();
  return;
  // Apply the fade-out class
  element.classList.add('animate__fadeOut', 'animate__faster');

  // Listen for the end of the transition
  element.addEventListener('animationend', function handler() {
    element.remove();
    element.removeEventListener('animationend', handler); // Clean up the event listener
  });
}
