import { anonUser, authUser, favourites, updateCookieParam } from "./cookies.js";
import showModal from './showModal.js';
import { populateCountryField } from "./initCountryRegionInputs.js";
import { forEach } from "lodash";
declare const rater: any;
declare const GLightbox: any;
declare const SimpleMDE: any;
export default function initMisc() {

  initConfirmLinks(document);

  updateBusinessEditAdDescriptionCharCount();

  initSelectSiteCountry();

  initVATRegistered();
  initSimpleMDE();
  initMessages();
  initDistances();

}

//If an anchor has data-confirm attribute then show a confirm dialog before following the link.
export function initConfirmLinks(element: HTMLElement | Document) {
  element.querySelectorAll("a[data-confirm]").forEach((element) => {
    element.addEventListener("click", (e) => {
      let target = e.target as HTMLElement;
      target.getAttribute("data-confirm");
      if (!confirm(element.getAttribute("data-confirm"))) {
        e.preventDefault();
      }

      /*if (target.dataset.resetJob) {
        let spinner = target.querySelector(".reset-job-spinner") as HTMLElement;
        if (spinner) {
          spinner.classList.add("fa-spin");
        }
        fetch(target.dataset.resetJob, { method: "POST" }).then(response => {
          spinner.classList.remove("fa-spin");
        });
      }*/
    });
  });
}
export function initRangeSliderValue() {
  //Display the value of the range slider on the page.
  let inputRangeSliders = document.getElementsByClassName("input-range");
  Array.from(inputRangeSliders).forEach(slider => {
    let sliderElement = slider.getElementsByClassName("input-range-slider")[0] as HTMLInputElement;
    let inputElement = slider.getElementsByClassName("input-range-value")[0] as HTMLInputElement;

    sliderElement.value = inputElement.value;

    [sliderElement, inputElement].forEach(element => {
      element.addEventListener("input", (e) => {
        if (element.classList.contains("input-range-slider")) {
          inputElement.value = element.value;
        } else if (element.classList.contains("input-range-value")) {
          sliderElement.value = element.value;
        }
      })
    });
  });
}
export function showAuthorisedElements() {
  //Authorise classes are used to hide elements from users who do not have the required role.
  //This function will remove authorise classes if that user has the required role.

  let roles = authUser ? authUser.Roles.concat("auth") : ["anon"];
  roles.forEach(role => {
    let className = `authorise-${role.toLowerCase()}`;
    let elements = document.getElementsByClassName(className);
    Array.from(elements).forEach(element => {
      element.classList.remove(className);
    });
  })
}

export function initDistances() {
  // Calculate the distances in each search listing.
  // Distances are from the user's coordinates if GPS permission is granted.
  // Otherwise, distances are from the search location if the 'Location' URL parameter is present.

  const params = new URLSearchParams(window.location.search.toLowerCase());
  const locationParam = params.get('location');
  const latParam = params.get('lat');
  const lngParam = params.get('lng');

  const searchResults = document.querySelector(".searchresults") as HTMLElement;
  const distanceElements = document.getElementsByClassName("distancefromuser");

  if (!distanceElements || !searchResults) return;

  getOriginLocation().then((originData) => {
    if (originData) {
      const { originLocation, originType } = originData;

      let distanceUnits = params.get('distanceunits');
      distanceUnits = distanceUnits
        ? distanceUnits.toLowerCase()
        : (anonUser.DistanceUnits || 'km').toLowerCase();

      applyDistances(distanceElements, originLocation, originType, distanceUnits, locationParam);
    }
  });

  function getOriginLocation(): Promise<{ originLocation: { lat: number; lng: number }; originType: string } | null> {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Success: use user's GPS coordinates
            const originLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve({ originLocation, originType: 'user' });
          },
          () => {
            // Error: proceed with search location
            proceedWithSearchLocation(resolve);
          }
        );
      } else {
        // Geolocation not supported: proceed with search location
        proceedWithSearchLocation(resolve);
      }

      function proceedWithSearchLocation(resolve) {
        if (locationParam || (latParam && lngParam)) {
          if (searchResults.dataset.locationLat != null && searchResults.dataset.locationLng != null) {
            const originLocation = {
              lat: parseFloat(searchResults.dataset.locationLat),
              lng: parseFloat(searchResults.dataset.locationLng),
            };
            resolve({ originLocation, originType: 'search' });
          } else {
            // No location data: hide distances
            hideDistanceElements();
            resolve(null);
          }
        } else {
          // No URL location parameters: hide distances
          hideDistanceElements();
          resolve(null);
        }
      }
    });
  }

  function hideDistanceElements() {
    // Hide distance elements
    Array.from(distanceElements).forEach((result) => {
      const distanceElement = result as HTMLElement;
      distanceElement.classList.add("d-none");
    });
  }

  function applyDistances(
    distanceElements,
    originCoords,
    originType,
    distanceUnits,
    locationParam
  ) {
    let locationLabel = '';
    if (originType === 'user') {
      locationLabel = 'from your location';
    } else if (locationParam) {
      locationLabel = `from ${locationParam}`;
    } else {
      locationLabel = 'away';
    }

    Array.from(distanceElements).forEach((result) => {
      const distanceElement = result as HTMLElement;
      const destLat = parseFloat(distanceElement.getAttribute("data-latitude") || "0");
      const destLng = parseFloat(distanceElement.getAttribute("data-longitude") || "0");

      const distance = Math.round(
        distanceFromUser(destLat, destLng, originCoords.lat, originCoords.lng, distanceUnits)
      );
      const minDistance = parseFloat(distanceElement.getAttribute("data-mindistance") || "0");

      if (distance < minDistance) {
        distanceElement.innerHTML = `| < ${minDistance} ${distanceUnits} ${locationLabel}`;
      } else {
        distanceElement.innerHTML = `| ${distance} ${distanceUnits} ${locationLabel}`;
      }
      distanceElement.classList.remove("d-none");
    });
  }
}




function distanceFromUser(lat1: number, lng1: number, lat2: number, lng2: number, units: string, minDistance?: number) {
  if (!anonUser) return 0;

  let latLng = { Lat: anonUser.Latitude, Lng: anonUser.Longitude };

  let radiusOfEarthInKm = 6371.0;
  let radiusOfEarthInMiles = 3959.0;
  let c = Math.PI / 180.0;

  function radians(x: number) {
    return x * c;
  }

  lng1 = radians(lng1);
  lat1 = radians(lat1);
  lng2 = radians(lng2);
  lat2 = radians(lat2);

  var a = Math.sqrt(
    Math.pow(
      Math.sin((lat2 - lat1) / 2.0)
      , 2.0
    ) +
    (
      Math.cos(lat1) *
      Math.cos(lat2) *
      Math.pow(
        Math.sin((lng2 - lng1) / 2.0)
        , 2.0
      )
    )
  );

  var distance = (units === 'Miles' ? radiusOfEarthInMiles : radiusOfEarthInKm) * 2.0 * Math.asin(a > 1.0 ? 1.0 : a);

  return distance;
}

function initFeedbackModal() {
  let form = document.getElementById("frmSendFeedback") as HTMLFormElement;
  let urlPath = form.getElementById("SendFeedback_URLPath") as HTMLInputElement;
  urlPath.value = window.location.pathname;



}

//Initialise the Glightbox plugin if there are any lightbox elements on the page.
export function initGLightboxes() {
  let lightboxElements = document.querySelector('.glightbox');
  if (!lightboxElements) return;

  let accountPhotosGallery = document.getElementById("account-photos-gallery");
  let options = { closeOnOutsideClick: true };

  if (accountPhotosGallery) {
    let customSlideHTML = `<div class="gslide">
    <div class="gslide-inner-content">
        <div class="ginner-container">
            <div class="gslide-media">
            </div>
            <div class="gslide-description">
                <div class="gdesc-inner">
                    <h4 class="gslide-title"></h4>
                    <div class="gslide-desc"></div>
                    <a class="btn btn-sm btn-danger" onclick="deletePhoto(this)" title="Delete Photo"><i class="fas fa-trash-can"></i> Delete Photo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
    options["slideHTML"] = customSlideHTML;
  }

  var lightbox = GLightbox(options);

  (window as any).deletePhoto = deletePhoto;
  (window as any).lightbox = lightbox;
}

export function deletePhoto(e) {
  let lightbox = (window as any).lightbox;
  let element = e as HTMLAnchorElement;
  let ginnerContainer = element.closest(".ginner-container");
  let img = ginnerContainer.querySelector(".gslide-image img") as HTMLImageElement;
  let listingPhotos = document.querySelector(".listing-photos") as HTMLElement;
  if (img) {
    let activeSlide = lightbox.getActiveSlideIndex();
    let activePhoto = listingPhotos.children[activeSlide];
    let photoAnchor = activePhoto.querySelector("a");
    let photoId = photoAnchor.dataset.photoId;
    if (photoId && confirm("Are you sure you want to delete this photo?")) {

      fetch(`/Account/Photos/DeletePhoto/${photoId}`, { method: "POST" })
        .then(response => response.json())
        .then(data => {
          if (data.message === "Deleted") {
            lightbox.removeSlide(activeSlide);
            activePhoto.remove();
            lightbox.close();
          } else {
            alert("There was an error deleting the photo." + data.message);
            console.log("Unexpected response:", data);
          }
        })
        .catch(error => console.error("Error:", error));
    }
  }
}

// Function to increment the number in the ID and name attributes
export function incrementAttribute(attrValue: string, newValue?: number): string {
  // Simplified regex to target the first group of digits
  return attrValue.replace(/\d+/, (match) => {
    if (newValue !== undefined) {
      return newValue.toString();
    }
    return (parseInt(match, 10) + 1).toString();
  });
}

export function initProformaInvoice() {
  let frmPayNow = document.getElementById("frmPayNow") as HTMLFormElement;
  if (frmPayNow == null) return;

  frmPayNow.querySelector("#DownloadProforma").addEventListener("click", function (e) {
    let formData = new FormData(frmPayNow);
    if (formData.get("ServiceCode") != null) {
      let element = document.createElement('input') as HTMLInputElement;
      element.setAttribute('type', 'hidden');
      element.setAttribute('name', "btnDownloadProforma");
      element.setAttribute('value', "btnDownloadProforma");
      frmPayNow.appendChild(element);
      frmPayNow.submit();
      element.remove();
    } else {
      alert("Please choose a service to download a proforma invoice.");
    }

    e.preventDefault();
  });
}

export function initBackButton() {
  let backButton = document.getElementById("navbar-back-button");

  if (backButton) {
    backButton.addEventListener("click", () => {
      let path = window.location.pathname.toLowerCase();
      if (path.includes("/account/")) {
        window.location.href = "/Account/Home";
      }

      if (path.includes("/account/")) {
        window.location.href = "/Account/Home";
      }
    });
  }
}

export function initCountryPrivacySettings() {
  let viewPermissionType = document.getElementById("ViewPermissionsType") as HTMLSelectElement;
  let viewPermissionCountry = document.getElementById("ViewPermissionsControlCountry") as HTMLSelectElement;
  let viewPermissionContinent = document.getElementById("ViewPermissionsControlContinent") as HTMLSelectElement;
  let viewPermissionsMode = document.getElementById("ViewPermissionsMode") as HTMLSelectElement;

  if (viewPermissionType == null) return;

  viewPermissionType.addEventListener("change", (e) => {
    updatePrivacySettingForm();
  });


  viewPermissionsMode.addEventListener("change", (e) => {
    updateViewPermissionsModeStyle()
  });

  function updatePrivacySettingForm() {
    if (viewPermissionType.value == "Anyone") {

      viewPermissionCountry.classList.add("d-none");
      viewPermissionContinent.classList.add("d-none");
      viewPermissionsMode.classList.add("d-none");
    } else if (viewPermissionType.value == "Country") {
      viewPermissionCountry.classList.remove("d-none");
      viewPermissionContinent.classList.add("d-none");
      viewPermissionsMode.classList.remove("d-none");
    } else {
      viewPermissionCountry.classList.add("d-none");
      viewPermissionContinent.classList.remove("d-none");
      viewPermissionsMode.classList.remove("d-none");
    }
  }

  function updateViewPermissionsModeStyle() {
    if (viewPermissionsMode.value == "Allow") {
      viewPermissionsMode.classList.add("text-success");
      viewPermissionsMode.classList.remove("text-danger");
    } else {
      viewPermissionsMode.classList.remove("text-success");
      viewPermissionsMode.classList.add("text-danger");
    }
  }

  updatePrivacySettingForm();
  updateViewPermissionsModeStyle();
}

function updateBusinessEditAdDescriptionCharCount() {
  let selectServiceCode = document.getElementById("ServiceCode") as HTMLSelectElement;
  let adDescription = document.getElementById("AdDescription") as HTMLTextAreaElement;

  if (!selectServiceCode || !adDescription) {
    return;
  }

  selectServiceCode.addEventListener("change", (e) => { });
}
function initSimpleMDE() {
  let editors = document.querySelectorAll(".simplemde");
  //foreach editor 

  if (!editors) return;
  editors.forEach(editor => {
    var simplemde = new SimpleMDE({
      element: editor as HTMLElement,

      toolbar: ["bold", "italic", "heading-1", "heading-2", "heading-3", "|", "quote", "ordered-list", "unordered-list", "|", "preview", {
        name: "guide",
        action: function customFunction(editor) {
          // Add your own code
          let markdownGuide = generateMarkdownGuide() as HTMLElement;
          showModal("Markdown Guide", markdownGuide.outerHTML);
        },
        className: "fa fa-question",
        title: "Markdown Guide",
      },],
      status: [{
        className: "characters", // Optional: custom class name for the character counter
        defaultValue: function (el) {
          el.innerHTML = "0 Characters";
        },
        onUpdate: function (el) {
          var charCount = simplemde.value().length;
          el.innerHTML = charCount + " Characters";
        }
      }],
      parsingConfig: {

      }
    });
  });
}

function generateMarkdownGuide() {
  // Define the rows as an array of objects
  let rows = [
    { toGet: '<h4>Heading</h4>', typeThis: '#### Underlined Text' },
    { toGet: '<h5>Smaller heading</h5>', typeThis: '##### Underlined Text' },
    { toGet: '<b>Bold Text</b>', typeThis: '**Bold Text**' },
    { toGet: '<i>Italic Text</i>', typeThis: '*Italic Text*' },
    { toGet: '<ul><li>list line 1</li><li>list line 2</li></ul>', typeThis: '* list line 1 <br /> * list line 2' },
    { toGet: 'A horizontal rule <br /> <hr>', typeThis: 'A horizontal rule <br /> ---' },
  ];

  // Create the table element
  let table = document.createElement('table');
  table.classList.add("table");

  // Create the header row
  let headerRow = document.createElement('tr');

  // Create and append the first header cell
  let headerCell1 = document.createElement('th');
  headerCell1.innerText = 'To get this..';
  headerRow.appendChild(headerCell1);

  // Create and append the second header cell
  let headerCell2 = document.createElement('th');
  headerCell2.innerText = 'Type this...';
  headerRow.appendChild(headerCell2);

  // Append the header row to the table
  table.appendChild(headerRow);

  // Loop through the rows array to create and append each data row
  rows.forEach(row => {
    let dataRow = document.createElement('tr');

    // Create and append the first data cell
    let dataCell1 = document.createElement('td');
    dataCell1.innerHTML = row.toGet;
    dataRow.appendChild(dataCell1);

    // Create and append the second data cell
    let dataCell2 = document.createElement('td');
    dataCell2.innerHTML = row.typeThis;
    dataRow.appendChild(dataCell2);

    // Append the data row to the table
    table.appendChild(dataRow);
  });

  return table;

}

function initSelectSiteCountry() {
  let initialised = false;
  let selectSiteCountry = document.getElementById("select-website-country") as HTMLInputElement;
  if (selectSiteCountry == null) return;

  let selectedCountry = selectSiteCountry.querySelector("option");
  if (selectedCountry == null) return;

  selectedCountry.value = anonUser.Country;
  selectedCountry.innerHTML = countryCodeToEmoji(anonUser.Country);

  selectSiteCountry.addEventListener("focus", () => {
    if (!initialised) {
      populateCountryField(selectSiteCountry).then(() => { initialised = true });
    }
  });

  selectSiteCountry.addEventListener("change", (e) => {
    let target = e.target as HTMLInputElement;
    let value = target.value;
    updateCookieParam("AnonUser", "Country", value);
    window.location.reload();
  });

}

export function countryCodeToEmoji(countryCode) {
  // Ensure the country code is uppercase
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());

  return String.fromCodePoint(...codePoints);
}

export function initVATRegistered() {
  let frmPayNow = document.getElementById("frmPayNow") as HTMLFormElement;
  if (frmPayNow) {
    let btnPay = frmPayNow.querySelector("#btnPay") as HTMLButtonElement;
    btnPay.addEventListener("click", (e) => {
      let formData = new FormData(frmPayNow);
      if (!formData.get("ServiceCode")) {
        alert("Please select an item to purchase.");
      }
    });
  }
  let vatRegisteredCheckbox = document.getElementById("VATRegistered") as HTMLInputElement;
  let vatNumberGroup = document.getElementById("VATNumberGroup") as HTMLInputElement;

  if (vatRegisteredCheckbox == null || vatNumberGroup == null) return;

  function toggleVATNumber() {
    if (vatRegisteredCheckbox.checked) {
      vatNumberGroup.style.display = "block";
    } else {
      vatNumberGroup.style.display = "none";
    }
  }

  // Initial check
  toggleVATNumber();

  // Add event listener
  vatRegisteredCheckbox.addEventListener("change", toggleVATNumber);
}

export function notifyBot(message: string) {
  try {
    let URL = window.location.href;
    fetch('/ajaxhelper/sendbotmessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(`🔴JAVASCRIPT ERROR🔴 \n\n URL: ${URL} \n ${message}`)
    })
      .then(response => response.json())
      .then(data => {
      })
      .catch(error => {
      });
  } catch (error) {
  }
}

export function handleErrors() {

  //Do not run if cookies are not enabled.
  const cookiesEnabled = document.cookie.indexOf("AnonUser=") !== -1;
  if (!cookiesEnabled) return false;

  let lastNotificationTime = 0; // Track the last time a notification was sent
  const notificationInterval = 5000; // Time interval in milliseconds (5 seconds)

  window.onerror = function (message, source, lineno, colno, error) {
    // Get the current time
    const now = Date.now();

    // Check if the notification interval has passed
    if (now - lastNotificationTime >= notificationInterval) {
      // Construct the error message
      var notifyMessage = `⚠️Message: ${message}\nSource: ${source}\nLine: ${lineno}\nColumn: ${colno}\nError: ${error ? error.stack : 'N/A'}`;

      // Call the notifyBot function with the error message
      notifyBot(notifyMessage);

      // Update the last notification time
      lastNotificationTime = now;
    }
  };
}

function initMessages() {
  let messagesGroup = document.getElementById("messages-group") as HTMLInputElement;
  let messagesForm = document.getElementById("messages-form") as HTMLFormElement;
  let messagesSearchInput = document.getElementById("messages-search-input") as HTMLInputElement;
  let messagesHideRepliedTo = document.getElementById("messages-hide-replied-to") as HTMLInputElement;

  if (!messagesGroup || !messagesSearchInput) return;

  let messages = messagesGroup.getElementsByClassName("message");
  let conversationChecks = messagesGroup.querySelectorAll(".message-select");
  let deleteButton = document.getElementById("btnDelete") as HTMLButtonElement;
  let messageFlagButtons = document.getElementsByClassName("message-flag");

  messagesSearchInput.addEventListener("input", (e) => {
    let searchTerm = messagesSearchInput.value.toLowerCase();
    Array.from(messages).forEach(message => {
      let messageText = message.querySelector(".message-sender-name");
      let messageSubject = message.querySelector(".message-subject");
      if (messageText.textContent.toLowerCase().includes(searchTerm) || messageSubject.textContent.toLowerCase().includes(searchTerm)) {
        message.classList.remove("d-none");
      } else {
        message.classList.add("d-none");
      }
    });
  });

  //Filter messages by flag.
  //If none/grey flag is selected, show all messages.
  //If any other flag is selected, show only messages with that flag.
  forEach(messageFlagButtons, (button) => {
    button.addEventListener("click", (e) => {
      let flag = button.getAttribute("data-flag");

      forEach(messageFlagButtons, (button) => {
        if (flag == "None") {
          button.classList.remove("opacity-50");
        } else {
          button.classList.add("opacity-50");
        }
      });

      button.classList.remove("opacity-50");


      Array.from(messages).forEach(message => {

        if (!message.classList.contains(`flag-${flag}`) && flag != "None") {
          message.classList.add("d-none");
        } else {
          message.classList.remove("d-none");
        }

      });
    });
  });

  let conversationsSelected = [];

  conversationChecks.forEach(check => {
    let checkbox = check as HTMLInputElement;
    check.addEventListener("change", (e) => {
      let messageIdCheckbox = checkbox.nextElementSibling as HTMLInputElement;

      if (checkbox.checked) {
        conversationsSelected = conversationsSelected.concat(checkbox.value);
        if (messageIdCheckbox) {
          messageIdCheckbox.checked = true;
        }
      } else {
        conversationsSelected = conversationsSelected.filter(conversation => conversation != checkbox.value);
        if (messageIdCheckbox) {
          messageIdCheckbox.checked = false;
        }
      }
      showDeleteButton();
    });
  });

  deleteButton.addEventListener("click", (e) => {
    e.preventDefault();
    if (confirm(`Are you sure you want to delete ${conversationsSelected.length} conversations? This cannot be undone.`)) {
      messagesForm.submit();
    }
  });

  //Filter messages whether the user has replied to them or not.
  messagesHideRepliedTo.addEventListener("change", (e) => {
    let check = e.target as HTMLInputElement;
    Array.from(messages).forEach(message => {
      if (message.classList.contains("replied-to") && check.checked) {
        message.classList.add("d-none");
      } else {
        message.classList.remove("d-none");
      }
    });
  });

  function showDeleteButton() {
    let messagesFormFooter = document.getElementById("messages-form-footer") as HTMLElement;
    let deleteButton = document.getElementById("btnDelete") as HTMLButtonElement;
    if (conversationsSelected.length > 0) {
      messagesFormFooter.classList.remove("d-none");
      messagesFormFooter.classList.remove("animate__slideOutDown");
      messagesFormFooter.classList.add("animate__slideInUp");
      deleteButton.innerText = `Delete ${conversationsSelected.length} conversation(s)`;
    } else {
      messagesFormFooter.classList.add("animate__slideOutDown");
      messagesFormFooter.classList.remove("animate__slideInUp");
    }
  }
}